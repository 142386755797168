import Vue from 'vue'
import Vuetify from 'vuetify/lib'

Vue.use(Vuetify)

export default new Vuetify({
  theme: {
    dark: false,
    options: {
      customProperties: true
    },
    themes: {
      light: {
        primary: { // A
          base: '#9B124F',
          darken1: '#9B124F', // R
          lighten1: '#FFCCCC' // C
        },
        primaryBtnText: { // A'
          base: '#FFFFFF'
        },
        secondary: { // B
          base: '#E28900',
          darken1: '#754700' // D
        },
        secondaryBtnText: { // B'
          base: '#FFFFFF'
        },
        headerBack: { // E
          base: '#EE1132'
        },
        headerText: { // F
          base: '#FFFFFF'
        },
        logoutBtnText: { // G
          base: '#FFFFFF'
        },
        topSchoolNamePc: { // H
          base: '#EE1132'
        },
        topSecondaryBtn: { // I
          base: '#FF0619'
        },
        topSecondaryBtnText: { // J
          base: '#FFFFFF'
        },
        topPrimaryBtn: { // K
          base: '#CF001C'
        },
        topPrimaryBtnText: { // L
          base: '#FFFFFF'
        },
        topBtnUpText: { // M
          base: '#FFFFFF'
        },
        primaryOutLineBtnText: { // N
          base: '#9C0000'
        },
        secondaryOutLineBtnText: { // O
          base: '#E28900'
        },
        topBtnUpTextSp: { // P
          base: '#9B124F'
        },
        topBtnBackSp: { // Q
          base: '#FFFFFF'
        },
        eventHeaderBack: {
          base: '#9B124F'
        },
        eventHeaderText: {
          base: '#FFFFFF'
        },
        eventTopBorderSub: {
          base: '#9B124F'
        },
        accent: '#162988', // datepickerなどの色
        formBackground: '#F6f6f6'
      },
      dark: {
        primary: '#FF5252',
        secondary: '#43A047',
        accent: '#162988',
        formBackground: '#F6f6f6'
      }
    }
  }
})
